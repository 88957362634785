<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<el-row>
				<el-col class="yu_input_label" :span="8">
					集团id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_pinpai_info.pipa_jituan_id"></el-input>
				</el-col>
			</el-row>
			<br />
			 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					品牌名称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_pinpai_info.pipa_name"></el-input>
				</el-col>
			</el-row>
			<br />
			   
			  
			
			<el-row class="yu_content_right">
				<el-button @click="pinpai_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import pinpai_set_get from "./expand/pinpai_set_get.js";
	import pinpai_set_post from "./expand/pinpai_set_post.js";

	export default {

		props: ["m_pipa_id", "parent"],
		data() {
			return {
				m_shpi_shan_id: "",
				m_pinpai_info: [],
				m_img: '',
				m_img2: '',
				m_shpi_name: "",
				m_shpi_yuanjia: "",
				m_shpi_chengben: "",
			}
		},
		created() {
			const that = this;
			that.pinpai_set_get = new pinpai_set_get(that);
			that.pinpai_set_get.m_main();
		},
		methods: {
			pinpai_set() {
				const that = this;
				that.pinpai_set_post = new pinpai_set_post(that);
				that.pinpai_set_post.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_img2 = res.upload_file;
				this.m_img = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
