import request from "tools/request.js"

//商品设置 提交
class shangpin_set_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		 
		  
		let m_pinpai_info 		= that.parent.m_pinpai_info;  
		let m_pipa_id 			 	= that.parent.m_pipa_id;
		let session 				= localStorage.getItem("session"); 
		  
		let params = { 
			m_pinpai_info : m_pinpai_info, 
			m_pipa_id : m_pipa_id,
			session : session
		} 
		
		request.post('/pinpai/pinpai_info_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.pinpai_get.m_main(); 
		})
		 
	}
}

export default shangpin_set_post