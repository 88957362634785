import request from "tools/request.js"

//建筑分类 添加建筑分类
class pinpai_add_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		   
		let session 					= localStorage.getItem("session"); 
  
		let params = { 
			pipa_jituan_id : that.parent.pipa_jituan_id,
			pipa_name : that.parent.pipa_name, 
			session : session
		} 
		
		request.post('/pinpai/pinpai_add_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_1 = false;
			that.parent.parent.pinpai_get.m_main(); 
		})
		 
	}
}

export default pinpai_add_post