<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br />
			
			  
			<el-row>
				<el-col class="yu_input_label" :span="8">
					集团id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="pipa_jituan_id"></el-input>
				</el-col>
			</el-row>
			<br />
			 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					品牌名称:
				</el-col>
				<el-col :span="16">
					<el-input v-model="pipa_name"></el-input>
				</el-col>
			</el-row>
			<br />
			   
  

			<el-row class="yu_content_right">
				<el-button @click="pinpai_add" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import pinpai_add_post from "./expand/pinpai_add_post.js";

	export default {

		props: ["parent"],
		data() {
			return { 
				pipa_jituan_id: "", 
				pipa_name: "", 
			}
		},
		created() {
			const that = this;
			// that.shoppe_add_info_get = new shoppe_add_info_get(that);
			// that.shoppe_add_info_get.m_main();
		},
		methods: {
			pinpai_add() {
				const that = this;
				that.pinpai_add_post = new pinpai_add_post(that);
				that.pinpai_add_post.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;  
				that.m_img2 = res.img_val;
				this.m_img = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess_1(res, file) {
				const that = this;  
				that.m_img2_1 = res.img_val;
				this.m_img_1 = URL.createObjectURL(file.raw);
			}, 
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 png 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
